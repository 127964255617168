$background_color-primary: $theme-color_primary-600;
$border_color-primary: $theme-color_primary-400 ;

$background_color-secondary: $theme-color_secondary-600;
$border_color-secondary: $theme-color_secondary-400;

$font_color-neutral: $white;
$font_color-600: $theme-color_neutral-600;


%checked-style {
  background-color: $background_color-primary;
  border-color: $border_color-primary;
  color: $font_color-neutral;
}

%normal-style {
  background-color: $font_color-neutral;
  border-color: $font_color-neutral;
  color: $font_color-600;
}

nx-radio-toggle:not(.tab) {
  width: 100%;

  .nx-radio-toggle {
    flex-wrap: wrap;
    justify-content: space-between;

    @include pad {
      justify-content: center;
    }

    nx-radio-toggle-button {
      margin-bottom: 20px;
      width: 280px;
      height: 60px;

      @include pad {
        margin-bottom: 16px;
        width: 100%;

        &:last-child {
          margin-bottom: 0px
        }
      }

      &.error {
        .nx-radio-toggle__input:disabled + .nx-radio-toggle__label-container {
          background-color: $background_color-secondary;
          border-color: $border_color-secondary;
          color: $font_color-neutral;
          cursor: default;
          .nx-radio-toggle__label {
            cursor: default;
          }

          &:hover {
            background-color: $background_color-secondary;
            border-color: $border_color-secondary;
            color: $font_color-neutral;
            cursor: default;
          }
        }
      }
    }

    .nx-radio-toggle {
      &__label-container,
      &__label-container.nx-radio-toggle__label-container--first,
      &__label-container.nx-radio-toggle__label-container--last {
        @extend %normal-style;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        border-radius: 100px;
        border-width: 2px;
        padding: 18px 26px;
        justify-content: center;

        &:hover {
          @extend %checked-style;
        }
      }
    }

    .nx-radio-toggle__input:disabled + .nx-radio-toggle__label-container {
      @extend %normal-style;
      cursor: default;
      .nx-radio-toggle__label {
        cursor: default;
      }

      &:hover {
        @extend %normal-style;
        .nx-radio-toggle__label {
          cursor: default;
        }
      }
    }

    .nx-radio-toggle__input:checked + .nx-radio-toggle__label-container {
      @extend %checked-style;

      &:hover {
        @extend %checked-style;
      }
    }

    .nx-radio-toggle__input:checked:disabled + .nx-radio-toggle__label-container {
      @extend %checked-style;
      cursor: default;
      .nx-radio-toggle__label {
        cursor: default;
      }

      &:hover {
        @extend %checked-style;
        .nx-radio-toggle__label {
          cursor: default;
        }
      }
    }
  }

  @include pad {
    .nx-radio-toggle .nx-radio-toggle__input:checked + .nx-radio-toggle__label-container .nx-toggle-button__mobile-check.nx-icon--auto {
      display: none;
    }
  }
}
