nx-checkbox {

  .nx-checkbox__label.has-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $theme-color_neutral-800;
    justify-content: center;

    a {
      font-weight: 700;
      color: $theme-color_primary-600;
    }

    .nx-checkbox__control {
      background-color: $white;
      border: 1px solid $theme-color_neutral-200;
      width: 16px;
      height: 16px;
      min-width: initial;
    }

    .nx-checkbox__label-text {
      text-align: left;
      margin-left: 8px;
    }

    @include pad {
      font-size: 14px;
      text-align: left;

      .nx-checkbox__control {
        width: 18px;
        height: 18px;
      }

      .nx-checkbox__label-text {
        width: 295px;
      }
    }
  }
}

.custom.nx-checkbox.nx-checkbox--label-small .nx-checkbox__label.has-label span.nx-checkbox__control {
  margin-top: 4px;
}

.custom.nx-checkbox.nx-checkbox--label-small .nx-checkbox__input:checked + .nx-checkbox__label.has-label span.nx-checkbox__control {
  background-color: $theme-color_primary-600;
  border-color: $theme-color_primary-800;

  nx-icon svg path{
    fill: $white;
  }
}

.custom.nx-checkbox.nx-checkbox--label-small:not(.nx-checkbox--negative):not(.has-error):hover .nx-checkbox__input:not([disabled]) + .nx-checkbox__label.has-label span.nx-checkbox__control {
  border-color: $theme-color_primary-800;
}
.custom.nx-checkbox.nx-checkbox--label-small:not(.nx-checkbox--negative):not(.has-error):hover .nx-checkbox__input:not([disabled]):checked + .nx-checkbox__label.has-label span.nx-checkbox__control {
  background-color: $theme-color_primary-600;
}

.custom.nx-checkbox.nx-checkbox--label-small .nx-checkbox__input:disabled + .nx-checkbox__label.has-label span.nx-checkbox__control {
  background-color: $theme-color_neutral-200;
  border-color: $theme-color_neutral-200;
}
