/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@300;400;500;700;900&display=swap");
@import "@angular/cdk/overlay-prebuilt.css";
@import "@angular/cdk/a11y-prebuilt.css";
@import "./app/scss/break-point";
@import "./app/scss/palette";
@import "./app/scss/button";
@import "./app/scss/selector";
@import "./app/scss/accordion";
@import "./app/scss/tab";
@import "./app/scss/input";
@import "./app/scss/radio";
@import "./app/scss/checkbox";
@import "./app/scss/menu";
@import "./app/scss/toast";

* {
  box-sizing: border-box;
  margin: 0;
  font-family: "Noto Sans TC", sans-serif;
}

// html.cdk-global-scrollblock {
//   // overflow-y: initial;
// }

body,
:host {
  background-color: $theme-color_primary-100;
  color: $black;
  overflow-x: hidden;
}

.desktop {
  @include pad {
    display: none !important;
  }
}

.mobile {
  display: none !important;

  @include pad {
    display: block !important;
  }
}

.cdk-overlay-pane:not(.toast-msg) {
  nx-modal-container.nx-modal__container {
    padding: initial;
    margin: initial;
    border-radius: 40px;
    border: 2px solid $white;
    background-color: $theme-color_neutral-060;
    backdrop-filter: blur(30px);
    box-shadow: 0px 16px 80px 0px $shadow-color_008;

    @include pad {
      padding: 16px;
      margin: 0 32px;
    }
  }

  &.small nx-modal-container.nx-modal__container {
    border-radius: 24px;

    @include pad {
      padding: initial;
      margin: initial;
    }
  }
}

.cdk-overlay-pane.toast-msg {
  nx-modal-container.nx-modal__container {
    background-color: initial;
    box-shadow: initial;
  }
}

.cdk-overlay-backdrop.backdrop {
  background-color: $theme-color_neutral-100;
  opacity: 0.5;
}

app-card-news {
  @include pad {
    width: 100%;
  }
}

// ViewEncapsulation.None 會有不確定的問題，所以先暫時用全域 CSS 來修改 innerHTML 樣式
.innerHTML__content {
  ul,
  ol {
    padding-left: 40px;
    word-break: break-all;
  }

  p {
    word-break: break-all;
  }
}
