nx-radio-toggle.tab {
  @include pad {
    overflow: auto;
  }

  .nx-radio-toggle {
    border-radius: 100px;
    background: $white;
    width: fit-content;
    padding: 8px;

    @include pad {
      flex-direction: initial;
    }
  }

  .nx-radio-toggle__label-container {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $theme-color_neutral-800;
    border: none;
    border-radius: 100px;
    padding: 12px 24px;
    transition: initial;
    white-space: nowrap;

    @include pad {
      padding: 8px 16px;
    }

    &:hover {
      background: $theme-color_primary-100;
      color: $theme-color_neutral-800;
      border-radius: 100px;
      border: none;
    }
  }

  .toggle-tab {


    .nx-radio-toggle__input.cdk-keyboard-focused + .nx-radio-toggle__label-container {
      box-shadow: none;
    }

    .nx-radio-toggle__input:active + .nx-radio-toggle__label-container,
    .nx-radio-toggle__input:checked + .nx-radio-toggle__label-container {
      background: $theme-color_primary-600;
      color: $white;
      border-radius: 100px;
      border: none;
    }

    @include pad {
      .nx-radio-toggle__input:checked + .nx-radio-toggle__label-container .nx-radio-toggle__label .nx-toggle-button__mobile-check {
        display: none;
      }
    }
  }
}
