nx-accordion.nx-accordion.home {
  nx-expansion-panel {
    width: min-content;
    position: relative;
  }

  .nx-expansion-panel--regular {
    .nx-expansion-panel__header-content {
      box-shadow: 0px 4px 16px $shadow-color_010;
    }

    &:not(:first-child) {
      margin-top: 24px;
    }

    @include pad {
      &:last-child, &.nx-expanded {
        .nx-expansion-panel__header-content {
          box-shadow: 0px 4px 16px $shadow-color_010;
        }
      }

      &:not(:first-child) {
        margin-top: 8px;
      }
    }
  }

  .nx-expansion-panel {
    &__header-content {
      width: max-content;
      padding: 0;
      background-color: $theme-color_neutral-060;
      backdrop-filter: blur(20px);
      border-radius: 100px;
    }

    &__header-title {
      color: $theme-color_neutral-800;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px ;
      padding: 16px 24px 16px 10px;
      margin: inherit;

      & + nx-icon {
        display: none;
      }

      &::before {
        content: "Q";
        background: $theme-color_secondary-600;
        color: $white;
        font-weight: 700;
        font-size: 18px;
        line-height: 28px;
        width: 36px;
        height: 36px;
        padding: 3px 10px 5px;
        border-radius: 100px;
        margin-right: 10px;
      }

      @include pad {
        font-size: 14px;
        padding: 8px 16px 8px 10px;

        &::before {
          font-size: 14px;
          line-height: 24px;
          width: 24px;
          height: 24px;
          padding: 4px 10px;
          border-radius: 100px;
          margin-right: 8px;
        }
      }

    }

    &__header.nx-expanded {
      .nx-expansion-panel__header-content {
        background-color: $black-080;
        backdrop-filter: initial;
      }

      .nx-expansion-panel__header-title {
        color: $white;
      }
    }


    &__content {
      background: $black-080;
      box-shadow: 0px 4px 16px $shadow-color_012;
      // backdrop-filter: blur(20px);
      border-radius: 28px;
      margin: 8px 0;
      position: absolute;
      z-index: 50;

      @include pad {
        border-radius: 20px;
      }
    }

    &__body {
      color: $theme-color_neutral-000;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      padding: 16px 24px;

      @include pad {
        padding: 8px 16px;
      }
    }
  }
}

nx-accordion.nx-accordion.result {

  .nx-expansion-panel--regular {
    border: 1px solid $theme-color_primary-400;

    &.nx-expanded .nx-expansion-panel__header-content {
      @include pad {
        box-shadow: none;
      }
    }
  }

  .nx-expansion-panel--regular:not(:first-child) {
    margin-top: 0px;
    border-top: initial;
  }

  .nx-expansion-panel--regular:not(.is-disabled) .nx-expansion-panel__header-content:hover {
    color: $black;
  }

  :not(.is-disabled) .nx-expansion-panel__chevron {
    color: $theme-color_neutral-400;
  }

  .nx-expansion-panel--regular:not(.is-disabled) .nx-expansion-panel__header-content:hover .nx-expansion-panel__chevron.nx-icon--auto {
    color: $theme-color_neutral-400;
  }

  nx-expansion-panel-header {
    .nx-expansion-panel__header-content {
      padding: 18px 24px 18px 24px;
      color: $black;
      background-color: $theme-color_primary-100;

      @include pad {
        box-shadow: none;
        padding-right: 16px;
        padding-left: 16px
      }

      &.nx-expanded {
        padding: 18px 24px 10px;
      }
    }

    .nx-expansion-panel__header-content nx-expansion-panel-title.nx-expansion-panel__header-title {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      display: flex;

      span {
        font-family: Montserrat;
        background: $theme-color_primary-600;
        color: $white;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
        border-radius: 8px;
        margin-right: 16px;
        width: 24px;
        height: 24px;
        text-align: center;
        flex-shrink: 0;
      }

      @include pad {
        margin-right: 8px;
      }
    }
  }

  .nx-expansion-panel__content .nx-expansion-panel__body {
    background-color: $theme-color_primary-100;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $theme-color_neutral-800;
    padding: 6px 72px 14px 24px;
    display: flex;

    @include pad {
      padding-right: 48px;
      padding-left: 16px
    }

    span {
      font-family: Montserrat;
      background: $theme-color_secondary-600;
      color: $white;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      border-radius: 8px;
      margin-right: 16px;
      width: 24px;
      height: 24px;
      text-align: center;
      flex-shrink: 0;
    }
  }

  > *:nth-child(even) {
    nx-expansion-panel-header {
      .nx-expansion-panel__header-content {
        background-color: $theme-color_primary-050;
      }
    }

    .nx-expansion-panel__content .nx-expansion-panel__body {
      background-color: $theme-color_primary-050;
    }
  }

  > *:first-child {
    border-radius: 8px 8px 0 0;

    .nx-expansion-panel__header-content {
      border-radius: 8px 8px 0 0;
    }
  }

  > *:last-child {
    border-radius: 0 0 8px 8px;

    .nx-expansion-panel__header-content {
      border-radius: 0 0 8px 8px;

      @include pad {
        box-shadow: none;
      }
    }

    .nx-expansion-panel__body {
      border-radius: 0 0 8px 8px;
    }
  }
}
