nx-menu.mobile .nx-menu__wrapper {
  background-color: $theme-color_neutral-060;
  border-bottom: 1px solid $white;
  box-shadow: 0px 16px 80px 0px $shadow-color_008;
  backdrop-filter: blur(30px);
  height: fit-content;
  padding: 16px;
  z-index: 100;

  .nx-menu__item.nx-menu__item--small {
    border-bottom: none;
    text-align: center;
    padding: 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $theme-color_neutral-800;

    &:hover {
      color: $theme-color_primary-600;
    }
  }
}
