/* primary */
$theme-color_primary-050: #F5FAFF;
$theme-color_primary-100: #E8F3FF;
$theme-color_primary-200: #CDE4FE;
$theme-color_primary-400: #8FC2FC;
$theme-color_primary-600: #2984EC;
$theme-color_primary-700: #1774DF;
$theme-color_primary-800: #0A65CC;

/* secondary */
$theme-color_secondary-400: #FCCB8F;
$theme-color_secondary-600: #F7A23B;


/* neutral */
$theme-color_neutral-000: #F9FAFB;
$theme-color_neutral-060: rgba(255, 255, 255, 0.6);
$theme-color_neutral-080: rgba(255, 255, 255, 0.8);
$theme-color_neutral-100: #EBEFF4;
$theme-color_neutral-200: #DBE1E7;
$theme-color_neutral-300: #BBC3CC;
$theme-color_neutral-400: #9EA8B3;
$theme-color_neutral-600: #6B7580;
$theme-color_neutral-800: #3E454C;
$theme-color_neutral-900: #292E33;

$white: #FFFFFF;
$black: #141619;
$black-060: rgba(0, 0, 0, 0.6);
$black-080: rgba(0, 0, 0, 0.8);
$black-000: rgba(0, 0, 0, 0);
$darkgrey: #676767;
$error: #F96767;

/* shadow color */
$shadow-color_080: rgba(3, 86, 180, 0.80);
$shadow-color_008: rgba(3, 86, 180, 0.08);
$shadow-color_0078: rgba(3, 86, 180, 0.078);
$shadow-color_010: rgba(18, 56, 64, 0.10);
$shadow-color_012: rgba(18, 56, 64, 0.12);
