@mixin mobile {
  @media screen and (max-width: 320px) {
    @content;
  }
}

@mixin pad {
  @media screen and (max-width: 703px) {
    @content;
  }
}

@mixin desktop-sm {
  @media screen and (max-width: 992px) {
    @content;
  }
}

@mixin desktop {
  @media screen and (max-width: 1200px) {
    @content;
  }
}

@mixin desktop-lg {
  @media screen and (min-width: 1441px) {
    @content;
  }
}
