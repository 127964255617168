
$box-shadow_default:$shadow-color_0078;

.nx-formfield {
  background-color: $white;
  border-radius: 100px;
  border: 2px solid $white;
  position: relative;

  &.is-focused {
    border: 2px solid $theme-color_primary-400;
  }

  .nx-formfield__wrapper {
    height: 56px;
    padding: 16px 24px 16px 32px;
  }

  &.has-outline.nx-formfield--type-nx-dropdown .nx-formfield__wrapper,
  &.has-outline.nx-formfield--type-nx-multi-select .nx-formfield__wrapper {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  &.has-outline .nx-formfield__wrapper .nx-formfield__input-container {
    padding: initial;
    border: none;
    background-color: initial;
  }

  &.has-outline .nx-formfield__input-container nx-dropdown .nx-dropdown__container,
  &.has-outline .nx-formfield__input-container nx-multi-select .value {
    padding: 0 50px 0 32px;
  }

  &.has-error:not(.nx-formfield--negative) {
    border-color: $error;
  }

  &.has-error:not(.nx-formfield--negative).is-floating .nx-formfield__label-holder .nx-formfield__label,
  &.nx-formfield--type-nx-multi-select.has-outline.has-error:not(.is-focused) .nx-formfield__wrapper .nx-formfield__input .nx-formfield__label-holder .nx-formfield__label {
    font-weight: 700;
    color: $error;
  }

  & .nx-formfield__wrapper nx-icon {
    position: absolute;
    right: 0px;
    top: 0px;

    svg path {
      fill: $theme-color_neutral-400;
    }
  }

  .nx-formfield__input-container {
    border-bottom: none;
    padding: initial;
  }

  &[required] .nx-formfield__label-holder .nx-formfield__label span::after {
    content: "*";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    color: $theme-color_primary-600;
  }

  .nx-formfield__message {
    position: absolute;
    top: calc(50% - 12px);
    right: 50px;
    margin-top: 0px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $error;
  }
}

.nx-formfield__input .nx-formfield__label-holder .nx-formfield__label {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $theme-color_neutral-800;
}

.nx-formfield.is-focused:not(.nx-formfield--negative).has-outline .nx-formfield__flexfield .nx-formfield__input-container {
  border: initial;
  box-shadow: initial;
  // padding-left: 32px;
}

.nx-formfield.is-floating {
  & .nx-formfield__wrapper {
    padding: 24px 24px 9px 32px;
  }

  & .nx-formfield__flexfield {
    height: 24px;
  }

  &.has-outline .nx-formfield__wrapper {
    padding: 24px 0 9px 0;
  }

  & .nx-formfield__label-holder .nx-formfield__label,
  &.has-outline .nx-formfield__label-holder .nx-formfield__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    transform: translateY(-20px);
  }

  &.has-outline .nx-formfield__label-holder .nx-formfield__label {
    margin-left: 32px;
  }

  &.has-outline .nx-formfield__wrapper nx-icon {
    top: -11px;
    right: 24px;
  }
}




input.c-input,
nx-multi-select .value,
.nx-dropdown__rendered {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: $black;

  &::placeholder {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: $theme-color_neutral-300;
  }
}

nx-multi-select .value {
  span + span {
    display: none;
  }
}

.cdk-overlay-pane {
  margin-top: 8px;
  margin-bottom: 30px;

  .panel.is-outline{
    box-shadow: 0px 4px 16px 0px $box-shadow_default;
    background: $white;
    border-radius: 24px;

    // .items {
    //   padding: 16px;
    //   max-height: initial;
    // }

    .items nx-multi-select-option {
      padding: 16px;
    }

    .nx-checkbox__control {
      border-color: $theme-color_neutral-200;
    }

    .nx-checkbox.is-selected .nx-checkbox__control {
      background-color: $theme-color_primary-600;
      border-color: $theme-color_primary-800;

      svg path {
        fill: $white;
      }
    }

    .nx-checkbox__label {
      color: $theme-color_neutral-800;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }
}

div[id^="cdk-overlay"].cdk-overlay-pane .panel.is-outline .items {
  padding: 16px;
  max-height: initial;
}

div[id^="cdk-overlay"].cdk-overlay-pane .nx-dropdown__panel--in-outline-field {
  box-shadow: 0px 4px 16px 0px $box-shadow_default;
  border-radius: 24px;

  .nx-dropdown__panel-body {
    background: $white;
    padding: 16px;
    max-height: initial;
    border-radius: 24px;
  }

  .nx-dropdown-results__option {
    padding: 16px;

    &-checkicon {
      display: none;
    }

    &-label {
      color: $theme-color_neutral-800;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      padding: initial;
      margin: initial;
    }



    &:hover {
      .nx-dropdown-results__option-label {
        color: $theme-color_primary-600;
      }
    }
  }

  .nx-dropdown-item--active .nx-dropdown-results__option .nx-dropdown-results__option-label {
    box-shadow: none;

  }

  // .nx-selected.nx-dropdown-item--active .nx-dropdown-results__option-label
}

