$button-background_color-primary: $theme-color_primary-600;

button.nx-button {
  // font-weight: 700;
  // font-size: 16px;
  border-radius: 100px ;
  text-align: center;

  @include pad {
    width: initial;
  }

  &--primary[nxbutton] {
    color: $white;
    background-color: $button-background_color-primary;

    &:hover:not([disabled]):not(:active) {
      color: $white;
      background-color: $theme-color_primary-700;
    }

    &:active {
      color: $white;
      background-color: $theme-color_primary-800;
    }
  }

  &--primary[nxbutton][disabled] {
    background-color: $theme-color_primary-400;
    color: $white;
  }

  &--secondary[nxbutton] {
    border: 1px solid $theme-color_primary-600;
    background-color: $white ;
    color: $theme-color_primary-600;

    &:hover:not([disabled]):not(:active) {
      border: 1px solid $theme-color_primary-600;
      background-color: $theme-color_primary-100;
      color: $theme-color_primary-600;
    }

    &:active {
      border: 1px solid $theme-color_primary-600;
      background-color: $theme-color_primary-200;
      color: $theme-color_primary-600;
    }
  }

  &--tertiary[nxbutton] {
    color: $theme-color_neutral-600;
    background-color: $white;

    &:hover:not([disabled]):not(:active) {
      color: $theme-color_neutral-600;
      background-color: $theme-color_neutral-100;
      border-color: $theme-color_neutral-100;
    }

    &:active {
      color: $theme-color_neutral-600;
      background-color: $theme-color_neutral-200;
    }
  }

  &--small[nxbutton] {
    font-weight: 700;
    font-size: 14px;
    height: 32px;
    padding: 0 16px;
    // min-width: 88px;
  }

  &--small-medium[nxbutton] {
    font-weight: 700;
    font-size: 16px;
    height: 48px;
    padding: 0 32px;
    // min-width: 128px;
  }

  &--medium {
    font-weight: 700;
    font-size: 16px;
    height: 60px;
    padding: 0 32px;
    min-width: 200px;
  }

  &.cdk-keyboard-focused {
    box-shadow: none;
  }
}



