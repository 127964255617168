nx-radio-group {
  .nx-label--large .nx-label__content{
    color: $theme-color_neutral-800;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  &[required] .nx-label--large .nx-label__content::after {
    content: "*";
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 4px;
    color: $theme-color_primary-600;
  }

  .row {
    display: flex;
    justify-content: space-between;

    > * {
      width: 50%;
    }

    > * ~ * {
      margin-left: 12px;
    }

    .nx-radio-button--big-label .nx-radio__label.has-label {
      width: 100%;
      padding: 18px 32px;
      background: $white;
      border-radius: 100px;
      border: 2px solid $white;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: $theme-color_neutral-800;
    }

    .nx-radio__circle {
      border: 1px solid $theme-color_neutral-200;
      width: 22px;
      height: 22px;
    }



    .nx-radio__input:checked + label[id^="nx-radio"].nx-radio__label.has-label {
      border-color: $theme-color_primary-400;
    }

    .nx-radio__input:checked + label[id^="nx-radio"].nx-radio__label.has-label .nx-radio__circle {
      border-color: $theme-color_primary-800;
      background-color: $theme-color_primary-600;

      .nx-radio__dot {
        background-color: white;
        width: 10px;
        height: 10px;
        border-radius: 100px;
      }
    }

    :not(.nx-radio--negative):not(.has-error) .nx-radio__input:not(:disabled) + .nx-radio__label:hover {
      border-color: $theme-color_primary-400;
    }
  }
}

.custom.nx-radio-button--big-label:not(.nx-radio--negative):not(.has-error) .nx-radio__input:not(:disabled):checked + .nx-radio__label.has-label:hover div.nx-radio__dot.nx-radio__dot {
  background-color: white;
}


.custom.nx-radio-button--big-label:not(.nx-radio--negative):not(.has-error) .nx-radio__input:not(:disabled) + .nx-radio__label.has-label:hover div.nx-radio__circle.nx-radio__circle {
  border-color: $theme-color_primary-800;
}
